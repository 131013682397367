import React from 'react'
import Link from 'next/link'
const AboutSection = ({ AboutImageData, aboutSectionHeading, aboutSectionMainHeading, aboutSectionParagraph, about_button_link }) => {
  return (
    <section className="about-area pt-130 rpt-100 rel z-1">
    <div className="container">
      <div className="row justify-content-between">
        <div className="col-xl-5 col-lg-3">
          <div style={{ display: 'flex', justifyContent: 'center' }} className="about-image rmb-45 wow fadeInUp delay-0-2s">
            <img style={{ width: '375px', height: '375px' }} src='/assets/images/circle-text.webp' alt={AboutImageData.alt} />
          </div>
        </div>
        <div className="col-xl-7 col-lg-9">
          <div className="about-content wow fadeInUp delay-0-4s">
            <div className="section-title mb-40">
              <span className="sub-title mb-15">{aboutSectionHeading}</span>
              <h2>{aboutSectionMainHeading}</h2>
            </div>
            <div className="content">
              <p>{aboutSectionParagraph}</p>
              <Link legacyBehavior href={about_button_link.url}>
                <a className="read-more mt-10">
                  about Techquisite
                  <i className="far fa-arrow-right" />
                </a>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  )
}

export default AboutSection